import dataTables from "appdevcommon/datatables";
import common from "appdevcommon/common";

import Routing from "appdevcommon/router";

let counter = 0;

export async function load(searchForm, source, opts) {
    const options = $.extend(true, {
        autofocus: true,
        item: {
            identifier: item => item.pidm,
            label: item => `${item.lastName}, ${item.preferredName || item.firstName} (${item.bannerId}) ${item.username || ''}`,
            search: item => `${item.lastName} ${item.preferredName || item.firstName} ${item.bannerId} ${item.username || ''}`,
            selectedLabel: item => `${item.lastName}, ${item.preferredName || item.firstName} (${item.bannerId}) ${item.username || ''}`
        },
        clearSelectedIdOnLoad: true,
        keepSearchTerm: false,
        minLength: 4,
        searchClass: ".person-search",                  // This is the input element that will trigger the autocomplete
        selectedIdentifierClass: ".selected-pidm",      // This is the element where the unique id will be stored when a result is selected
        selectedSearchElement: ".selected-search-name", // This is the element that will display the selected result; can be same as searchClass
        selectedUserNameClass: ".selected-username",    // When present, this element holds the username of the selected result.
        showExpandedResults: false,
        studentProfileRoute: "web_student_profile",
        additionalStudentProfileRouteParameters: {},
        submitOnSelect: false,
    }, opts);

    const searchElement = searchForm.find(`${options.searchClass}:first`);
    const selectedElementId = options.selectedIdentifierClass !== null ? searchForm.find(`${options.selectedIdentifierClass}:first`) : null;
    const selectedUsernameElement = searchForm.find(`${options.selectedUserNameClass}:first`);
    const selectedSearchElement = searchForm.find(`${options.selectedSearchElement}:first`);

    if (searchElement.data("sac-initialized")) {
        return;
    }

    searchElement.data("sac-initialized", true);

    if (options.showExpandedResults) {
        $.get({
            url: Routing.generate("search-autocomplete-expanded-results")
        })
            .done(async function (data) {
                const expandedResultsTable = $("#expanded-results-table");
                if (expandedResultsTable.length === 0) {
                    $("body").append(data).ready(async function () {
                        await dataTables.create(expandedResultsTable);
                    });
                }
            })
            .fail(function () {
                console.debug("There was an error getting the expanded search results modal.")
            });
    }

    if ($(".ui-autocomplete-input", searchElement).length > 0) {
        searchElement.autocomplete("destroy");
    }

    if (selectedElementId !== null && options.clearSelectedIdOnLoad) {
        selectedElementId.val("");
    }

    let mySource;

    try {
        mySource = JSON.parse(JSON.stringify(source));
    } catch (e) {
        console.error(`Caught an exception parsing autocomplete source data: ${source}\n${e}`);
        console.warn(`Autocomplete for "${options.selectedSearchElement}" will not be available due to errors`);
        return;
    }

    searchElement.autocomplete({
        minLength: options.minLength,
        position: {my: "right top", at: "right bottom", collision: "flip"},
        create: function () {
            $(this).attr("autocomplete", "off");
        },
        source: function (request, response) {
            let term = request.term.replace(",","");
            return response(handleAutocomplete(term));
        },
        focus: function (event, ui) {
            if (!options.keepSearchTerm) {
                $(this).val(options.item.selectedLabel(ui.item));

                if (ui.item.value === "searchResultsExpand") {
                    $(this).val("");
                }
            }
            return false;
        },
        select: function (event, ui) {
            if (ui.item.value === "searchResultsExpand") {
                common.loadSpinner();
                event.preventDefault();
                const resultsDt = $("#expanded-results-table").DataTable();
                resultsDt.clear();

                $("ul.ui-autocomplete li").each(function (idx, student) {
                    let studentId = $(student).data("bannerid") || "";
                    let studentName = $(student).data("name") || "";
                    let studentPrograms = $(student).data("program") || "";
                    let studentPidm = $(student).data("pidm") || "";
                    let routeParams = $.extend({pidm: studentPidm}, options.additionalStudentProfileRouteParameters);
                    let studentNameCell = `<a href="${options.studentProfileRoute ? Routing.generate(options.studentProfileRoute, routeParams) : "#"}" data-toggle="tooltip" title="View Student">${studentName}</a>`;
                    resultsDt.row.add([studentId, studentNameCell, studentPrograms]);
                });

                resultsDt.row(0).remove().draw();
                common.initializeToolTips();
                common.unloadSpinner();

                $("#expanded-search-results-modal").modal("show")
            } else {
                const searchElementValue = options.item.selectedLabel(ui.item);

                if (typeof searchElementValue === "string" && searchElementValue.length > 0) {
                    searchElement.val(searchElementValue);
                    searchElement.attr("title", options.item.label(ui.item));
                }

                if (selectedElementId !== null) {
                    selectedElementId.val(options.item.identifier(ui.item)).trigger("change");
                }

                selectedUsernameElement.val(ui.item.username).trigger("change");

                selectedSearchElement.val(options.item.selectedLabel(ui.item));

                if (options.submitOnSelect) {
                    common.loadSpinner();
                    searchForm.first().trigger("submit");
                }

                return false;
            }
        },
        response: function (event, ui) {
            if (ui.content.length && options.showExpandedResults) {
                const expandResult = {value: "searchResultsExpand"};
                ui.content.unshift(expandResult);
            }
        },
        search: function (event) {
            // If we are searching a number with less than 5 characters, cancel the search
            // There are too many results for 8009 and the browser hangs
            let currentInput = $(event.currentTarget).val();
            if (/^\d+$/.test(currentInput) && currentInput.length < options.minLength) {
                event.preventDefault();
            }
        }
    });

    searchElement.each(function () {
        $(this).on("focus click", function () {
            const val = $(this).val();
            if (val.length > 0) {
                $(this).autocomplete("search");
            }
        });

        $(this).autocomplete("instance")._renderItem = function (ul, item) {
            if (item.value === "searchResultsExpand") {
                return $("<li>")
                    .append(`<div class="search-results-expand"><i class="fas fa-search" aria-hidden="true"></i>Expand search results</div>`)
                    .appendTo(ul);
            }
            let programData = [];
            if ("programs" in item) {
                item.programs.forEach(function (program) {
                    programData.push(program.programDesc)
                });
            }

            const searchItem = options.item.label(item);

            return $(`<li 
                        data-program="${programData.join("<br> ")}" 
                        data-name="${item.lastName}, ${item.firstName}"
                        data-username="${item.username}" 
                        data-bannerid="${item.bannerId}"
                        data-id="${options.item.identifier(item)}" 
                        data-pidm="${item.pidm}">`)
                .append(`<div>${searchItem}</div>`)
                .appendTo(ul);
        };
    });

    if (options.autofocus) {
        searchElement.focus();
    }

    function recursiveListUpdate(filteredList, keywords) {
        let newList = [];
        if (keywords[counter] !== "") {
            filteredList.forEach(element => {
                if (options.item.search(element).toLowerCase().indexOf(keywords[counter].toLowerCase()) >= 0) {
                    newList.push(element);
                }
            });
        } else {
            newList = filteredList;
        }
        counter++;
        if (counter === keywords.length) {
            return newList;
        }
        return recursiveListUpdate(newList, keywords);
    }

    function handleAutocomplete(term) {
        counter = 0;
        return recursiveListUpdate(mySource, term.split(" "));
    }
}

export default {
    load,
}
