import "datatables.net-scroller-bs4";
import "datatables.net-responsive-bs4";
import "datatables.net-rowreorder-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-select-bs4";
import "jquery-datatables-checkboxes";

import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-scroller-bs4/css/scroller.bootstrap4.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.css";
import "datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-select-bs4/css/select.bootstrap4.css";
import "jquery-datatables-checkboxes/css/dataTables.checkboxes.css";

import * as common from "./common"
import {getElementName} from "./plugin.utls.jquery";

$.fn.dataTable.ext.errMode = "throw";
// latest DataTables reorganizes oStdClasses and this is no longer necessary.
if (typeof $.fn.dataTableExt.oStdClasses.sWrapper !== "undefined") {
    $.fn.dataTableExt.oStdClasses.sWrapper = $.fn.dataTableExt.oStdClasses.sWrapper.replace(/\s?form-inline\s?/g, " ");
}

const errorsShown = [];

export async function create(obj, opts = {}, callback = () => {}) {
    // https://datatables.net/reference/option/
    let dom = `<"w-100" <"top float-right mb-2 ml-2 text-right dt-length-change"l>
            <"top float-right mb-2 dt-filtering"f>>
            t
            <"w-100" <"bottom float-left dt-table-info"i>
            <"bottom dt-pagination"p>>
            <"clear">`;
    if (opts.buttons !== undefined) {
        dom = `B${dom}`;
    }

    const customDrawCallback = opts.drawCallback !== undefined ? opts.drawCallback : $.noop;
    const customInitComplete = opts.initComplete !== undefined ? opts.initComplete : $.noop;

    delete opts.drawCallback;
    delete opts.initComplete;

    const options = Object.assign({}, {
        autoWidth: false,
        destroy: true,
        buttons: ["copy", "csv"],
        deferRender: true,
        // https://datatables.net/reference/option/dom
        dom: dom,
        drawCallback: function (settings) {
            const dt = this.api();
            const pagination = $(dt.table().container()).find(".dataTables_paginate");
            const dtPageInfo = dt.page.info();

            pagination.show();
            if (dtPageInfo.length === -1 || dtPageInfo.recordsDisplay <= dtPageInfo.length) {
                pagination.hide();
            }

            customDrawCallback.apply(this, arguments);
        },
        initComplete: function (settings, json) {
            alignFilter(obj);
            customInitComplete.apply(this, arguments);
        },
        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
        responsive: true,
        rowReorder: false,
        select: false,
        stateDuration: 0,
        stateSave: true,
    }, opts);

    if (typeof opts.language !== "undefined") {
        options.language = Object.assign({}, {
            loadingRecords: '<div class="single-mini-spin spinner-dark" aria-hidden="true"></div>',
            search: "_INPUT_",
            searchPlaceholder: "Search...",
        }, opts.language);
    }

    if (typeof opts.ajax !== "undefined") {
        // http://api.jquery.com/jQuery.ajax/
        options.ajax = Object.assign({}, {
            complete: function (jqXHR, textStatus) {
                alignFilter.call(this, obj);

                if (typeof opts.ajax.complete !== "undefined") {
                    opts.ajax.complete.apply(this, arguments);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                //abort states get thrown if a new choice is made mid-stream - it's not an error and thus causes user confusion.
                if (textStatus === "abort") {
                    return false;
                }
                const message = jqXHR.statusText === "Service Unavailable"
                    ? jqXHR.responseJSON.message
                    : "There was a problem processing your request. If the problem persists please contact the OneIT Helpdesk.";

                console.error("A(n) %s %s was encountered: %o", errorThrown, textStatus, jqXHR);

                if (errorsShown.indexOf(message) < 0) {
                    errorsShown.push(message);

                    common.loadAlert(message, {alertType: "danger", container: $("#flash-messages"), timeout: 8000, html: true});

                    if (typeof opts.ajax.error !== "undefined") {
                        opts.ajax.error.apply(this, arguments);
                    }
                }
            }
        }, opts.ajax);
    }

    // When a table already has rows in it, then the preInit event in
    // DataTables gets called after the table is initialized from the DOM.
    // This callback services as a true preInit.
    typeof callback === "function" && callback($.fn.dataTable);

    const table = obj.DataTable(options);
    table
        .on("xhr.dt", function () {
            console.debug("Clearing %s records for data table %s", table.rows().count(), getElementName(this));
            table.clear();
        })
        .on("init.dt", function () {
            console.debug("Loaded data table %s: %o", getElementName(this), options);
        });

    return table;
}

function alignFilter(table) {
    const marginBottom = "-32px";
    const maxHeight = `${parseInt(marginBottom) * -1}px`;

    table.parent().find(".data-table-filter").css("margin-bottom", marginBottom).css("max-height", maxHeight);
    table.parent().siblings(".data-table-filter").css("margin-bottom", marginBottom).css("max-height", maxHeight);
    table.closest("[id$=-collapse]").find(".data-table-filter").css("margin-bottom", marginBottom).css("max-height", maxHeight);
}

console.debug("Loaded library: datatables");

export default {
    create,
};
